import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from './home';
import Header from './header';
import Footer from './footer';

const maintenenceMode = true;

function Site(){
  if(maintenenceMode){
    return(
      <div className="App">
        <div className="site-message">
          <h1>
          Down for maintnence
          </h1>
        </div>
      </div>
    )
  }

  if(!maintenenceMode){
    return(
      <div className="App">
      <Header />
        <Router>
          <Route path="/" exact component={Home}></Route>
        </Router>
      <Footer />
    </div>
    )
  }
}

function App() {
  return (
    <Site />
  );
}

export default App;
