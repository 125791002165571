import React from "react";

export default class Cart extends React.Component{
    render(){
        return(
        <div id="cartContainer">
            <i className="bi bi-cart-fill"></i> Cart
        </div>
        );
    }
}