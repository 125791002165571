import React from "react";
import Cart from "./cart";
const images = require('./images/images.json');

export default class Header extends React.Component{
    constructor(){
        super();

        this.navigationOptions = [
            {name: 'Home', id:'home', path:'/'},
            {name: 'Shop', id:'shop', path:'/'},
            {name: 'About', id:'about', path:'/'},
            {name: 'Gallery', id:'gallery', path:'/'},
            {name: 'Series', id:'series', path:'/'},
        ];

        this.socialOptions = [
            {name: 'Instagram', id:'instagram', icon:<i className="bi bi-instagram"></i>, path:''},
            {name: 'Twitter', id:'twitter', icon:<i className="bi bi-twitter"></i>, path:''},
            {name: 'Youtube', id:'youtube', icon:<i className="bi bi-youtube"></i>, path:''}
        ];
    }
    polulateNavigation(){
        return(
            this.navigationOptions.map((option, index) => (
                <a href={option.path} key={index}>{option.name}</a>
            ))
        );
    }

    polulateSocials(){
        return(
            this.socialOptions.map((option, index) => (
                <a href={option.path} className="social-icon" id={option.id} key={index}>{option.icon}</a>
            ))
        );
    }

    render(){
        return(
            <div className="header">
                <Cart />
                <div className="row">
                    <div id="logo">
                        <img src={`data:image/jpeg;base64,${images.logoWht}`}></img>
                    </div>
                </div>
                <div className="row sub-header">
                    <div className="col"></div>
                    <div className="col sub-header-item nav-container">
                        {this.polulateNavigation()}
                    </div>
                    <div className="col sub-header-item social-container">
                        {this.polulateSocials()}
                    </div>
                </div>
            </div>
        );
    }
}