import React from "react";

export default class Home extends React.Component{
    render(){
        return(
        <div className="page">
            <div className="content-section landing-banner">
                
            </div>
            <div className="content-section latest-panel">
                news
            </div>
            <div className="content-section shop-panel">
                shop
            </div>
            <div className="content-section story-panel">
                story
            </div>
        </div>
        );
    }
}